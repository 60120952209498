#login .view {
  background-color: black;
  background-size: cover;
  height: 100vh;
}

#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0!important;
}
#login .md-form label {
  color: #ffffff;
}

#login .form-control {
  color: #000;
}
