.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,1) !important;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: .125rem;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: .84rem 2.14rem;
  font: 400 11px system-ui;
}

.menuArrow {
  margin-left: 5px;
  margin-bottom: 7px;
}

.gravatar {
  vertical-align: unset !important;
  margin-top: .25rem !important;
}
