body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.button-create {
  bottom: 45px;
  right: 24px;
}

#root {
  display: block;
  background-color: #f5f5f5;
}

html {
  background-color: black
}

.ril-toolbar {
  background-color: rgba(0,0,0,0) !important;
}

.site-header {
  z-index: 2000;
}

.white-text {
  color: white;
}
