.dark-toast {
  background: black !important;
}

.MuiTabs-indicator {
  background-color: black !important;
}

.ril__inner {
  background-color: #333333 !important;
}
