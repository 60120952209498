.upload-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #ffeead;
  flex-direction: column;
}

.upload-container > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-container > div .text {
  font-size: 0.3em;
  margin: 0;
  padding: 5px 0 0 0;
}

.upload-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px,1fr);
  grid-auto-flow: dense;
  padding: 20px;
  background-color: black;
}
