
img:hover {
  cursor: pointer;
}

.voting {
  background-color: black;

  .vote {
    max-height: 55vh;
    max-width: 100%;
    object-fit: contain;
  }

  .full-height {
    height: 100vh;
  }

  .title {
    color: white;
  }

  .slider {
    width: 50vw;
  }
}

.show-vote {
  .vote-result {
    max-width: 40vw;
    max-height: 20vh;
    object-fit: cover;
  }

  .full-height {
    height: 100vh;
  }


  .result {
    flex-direction: column;
  }
}

.vh70 {
  height: auto;
  max-height: max(80vh, 100vh - 200px);
  width: 95vw;
  max-width: 100%; /* to ensure it doesn't exceed the container's width */
  object-fit: contain; /* this will ensure the image maintains its aspect ratio */
}



.setup-voting {
  padding: 20px;
  min-height: 100vh;

  .middle {
    margin: 0 auto;
    width: 960px;
    display: block;
  }

  .instruction {
    padding: 0;
    font-size: 12px;
  }

  .box {
    background: white;
    padding: 20px;
    min-height: 260px;
    position: relative;
  }

  .list-form {
    margin: 0 0 1em;
    padding: 0 0;
    border: none;

    li {
      list-style: none;
      margin: 0 0 1em;

      > label {
        margin-top: 12px;
      }
    }


    label {
      width: 30%;
      display: block;
      float: left;
      line-height: 19px;
      font-size: 13px;
      cursor: pointer;
    }

    .list-form-details {
      font-size: 12px;
      display: block;
      clear: left;
      margin: .2em 0 0;
      width: 90%;
      padding-left: 30%;
    }

    .date-picker {
      margin: 0px;
    }

    .full {
      width: 60%;
    }

    .text {
      font-size: 14px;
      line-height: 1;
      padding: 8px;
      margin-right: 6px;
      width: 60%;
      border: 1px solid #dfdfdf;
      background: #fff;
      outline: 0;
      -webkit-box-shadow: inset 2px 2px 4px hsla(0, 0%, 78%, .1);
      box-shadow: inset 2px 2px 4px hsla(0, 0%, 78%, .1);
      -webkit-border-radius: 2px;
      border-radius: 2px;

    }

    .half {
      width: 30%;
    }
  }
}

.box {
  background: white;
  padding: 20px;
  margin: 20px 0 20px 0;
  min-height: 260px;
  position: relative;
}

.upload {
  .list-form {
    margin: 0 0 1em;
    padding: 0 0;
    border: none;

    li {
      list-style: none;
      margin: 0 0 0em;

      > label {
        margin-top: 12px;
      }
    }

    .half {
      width: 40%;
    }
  }
}

.border-chosen {
  border: 15px solid #0f7900;
}
