.reload {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 1em;
  background-color: bisque;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #666666;
  }
}
.reload.show {
  opacity: 1;
  visibility: visible;
}
