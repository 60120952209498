#profile-v1 .cascading-admin-card .admin-up .fa {
    margin-right: 1rem;
}

#profile-v1 .user-card .admin-up .data span {
    font-size: 15px;
}
#profile-v1 .profile-card p, .user-card .about-text h4 {
    font-weight: 300;
}

.classic-admin-card .card-body {
    color: #fff;
    margin-bottom: 0;
    padding: .9rem;
}

.classic-admin-card .card-body p {
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 0px;
}

.classic-admin-card .card-body h4 {
    margin-top: 10px;
}

.cascading-admin-card .admin-up .fa {
    padding: 1.7rem;
    font-size: 2rem;
    color: #fff;
    text-align: left;
    border-radius: 3px;
}

.cascading-admin-card .admin-up .fa, .z-depth-2 {
    -webkit-box-shadow: 0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
    box-shadow: 0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
}

.cascading-admin-card .admin-up .data {
    float: right;
    margin-top: 2rem;
    text-align: right;
}
.cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px;
}
